.main-header-nav {
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

.home-logo {
    margin-right: 100px;
}

.main-menu-dropdown {
    background: black;
}
.button {
    color: white;
    background: black;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
}

.button.ant-btn {
    box-shadow: none;
}

.button.ant-btn:hover {
    background: #ff6600;
}

.button.ant-btn:active {
    background: #ff6600;
}

.banner-image {
    width: 100%;
    height: auto;
    background-image: url("../../assets//banner-image.jpg");
    background-repeat: no-repeat;
    background-size: contain;
}
.home-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background: white;
    color: grey;
    margin-top: 25px;
}

.home-h1 {
    font-size: 54px;
    color: black;
    font-weight: bold;
    letter-spacing: 8px;
}

.home-h2 {
    display: flex;
    margin: 25px 0px 25px 0px;
    color: black;
}

.home-p {
    text-align: left;
    font-size: 18px;
}

.image-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

.home-content-image {
    background-repeat: no-repeat;
    background-size: contain;
}

.button-container {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 100%;
}

.membership-button {
    height: 50px;
    letter-spacing: 4px;
    font-weight: bold;
    font-size: 16px;
    color: white;
    background: #ff6600;
    box-shadow: none;
    border: none;
}

.ant-btn.membership-button:hover {
    background: black;
    color: white;
}

/* .footer-area {
    background-image: url("../../assets/footer-image.jpg");
    background-position: right;
    background-size: cover;
} */

.footer-container {
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 175px;
    color: white;
    letter-spacing: 1px;
    opacity: 0.7;
}

.social-container {
    padding: 0px 25% 0px 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    width: 100%;
    height: 100%;
}

.info-container {
    display: flex;
    align-items: center;
}

.fb-center {
    padding-bottom: 10px;
}
